import React, { useState, useContext } from "react";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { UserContext } from "context/UserContext";
import { PageLayout } from "./PageLayout";
import { postChangePassword } from "../../apis";
import { Grid, Typography, Button } from "@material-ui/core";
import { Check, LockOutlined } from "@material-ui/icons";
import { SmarterTextField } from "@components/input";
import { UserProfileDialog } from "./components/UserProfileDialog";
import { ToggleVisibilityIcon } from "@components/toggle-visibility-icon";
import { isPasswordValid } from "../../utils/utils";
import { makeStyles } from "@material-ui/core/styles";
import { useStylesBase } from "./useStylesBase.js";

const useStyles = makeStyles((theme) => ({
  requiredLabels: {
    marginTop: "30px",
    paddingLeft: "5px",
  },
  passwordRequirementTitle: {
    color: "#000",
    fontWeight: 600,
    fontSize: theme.spacing(1.2),
    lineHeight: `${theme.spacing(1.8)}px`,
    display: "block",
    marginBottom: theme.spacing(1),
  },
  passwordRequirementElement: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    fontSize: theme.spacing(1.2),
    lineHeight: `${theme.spacing(1.8)}px`,
  },
  passwordRequirementImage: {
    marginRight: theme.spacing(0.8),
    width: theme.spacing(2),
  },
  textLabel: {
    fontSize: theme.spacing(1.6),
    lineHeight: `${theme.spacing(2.4)}px`,
    color: "#000",
    marginBottom: theme.spacing(1),
    display: "block",
  },
  buttonCancel: {
    backgroundColor: "#fff",
    color: "#000",
    borderColor: "#000",
  },
  buttonContinue: {
    backgroundColor: "#000",
    color: "#fff",
  },
}));
export const AccountSecurity = (_props) => {
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordFieldVisibilities, setPasswordFieldVisibilities] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [activeModalType, setActiveModalType] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const baseClasses = useStylesBase();
  const classes = useStyles();
  const cookies = new Cookies();
  const navigate = useNavigate();
  //let isAuth0 = cookies.get('auth0') || window.location.hostname.endsWith('.fwd.com');
  let isAuth0 = false;
  const { passwordConfiguration, passwordRules } = useContext(UserContext);

  const onChangePassword = (key) => {
    return (value) => {
      if (key === "newPassword") {
        setErrors({ ...isPasswordValid(value, passwordRules) });
      }
      setPassword((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    };
  };

  const createOnToggleVisibilityHandler = (key) => {
    return function () {
      setPasswordFieldVisibilities((prev) => {
        return {
          ...prev,
          [key]: !prev[key],
        };
      });
    };
  };

  const onCancel = () => {
    setPassword({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const dialogConfirmation = () => {
    if (activeModalType === "passwordChanged") {
      localStorage.clear();
      if(isAuth0) {
        navigate("/auth-login");
      }
      else {
        navigate("/login");
      }
    } else if (activeModalType === "errorPassword" || activeModalType === "passwordSame") {
      setIsDialogOpen(false);
    }
  };

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("old_password", password.oldPassword);
    formData.append("new_password", password.newPassword);
    formData.append("repeat_password", password.confirmPassword);

    setIsDialogOpen(true);
    if(password.oldPassword === password.newPassword) {
      setActiveModalType("passwordSame");
    }
    else {
      try {
        await postChangePassword(formData);
        setActiveModalType("passwordChanged");
      } catch (err) {
        setActiveModalType("errorPassword");
      }
    }
  
  };
  const userDialogClose = () => {
    setIsDialogOpen(false);
  };


  let isChangeButtonPasswordDisabled =
    !errors["minLength"] ||
    !errors["maxLength"] ||
    !errors["minUppercase"] ||
    !errors["minLowercase"] ||
    !errors["specialChar"] ||
    !errors["numberCount"] ||
    password.newPassword !== password.confirmPassword;
  const errorColor = "#62BE37";
  const successColor = "#ABABAB";

  let isPasswordError = password.confirmPassword.length && (password.newPassword !== password.confirmPassword)

  return (
    <PageLayout title="Account Security" activePage="account-security">
      <div className={baseClasses.container}>
        <Typography
          component="h2"
          variant="subtitle1"
          className={baseClasses.pageTitle}
        >
          Account Security
        </Typography>
        <Typography
          component="h3"
          variant="subtitle2"
          className={baseClasses.formTitle}
        >
          Change Password
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={baseClasses.textField}>
              <label
                className={classes.textLabel}
                htmlFor="userProfile-old_password"
              >
                Old Password
              </label>
              <LockOutlined className="lock-outlined-icon" />
              <SmarterTextField
                id="userProfile-old_password"
                inputProps={{ "data-testid": "userProfile-old-password" }}
                value={password.oldPassword}
                type={
                  passwordFieldVisibilities.oldPassword ? "text" : "password"
                }
                onChange={onChangePassword("oldPassword")}
                allowClear={false}
              />
              <ToggleVisibilityIcon
                visible={passwordFieldVisibilities.oldPassword}
                onToggle={createOnToggleVisibilityHandler("oldPassword")}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={baseClasses.textField}>
              <label
                className={classes.textLabel}
                htmlFor="userProfile-new_password"
              >
                New Password
              </label>
              <LockOutlined className="lock-outlined-icon" />

              <SmarterTextField
                id="userProfile-new_password"
                inputProps={{ "data-testid": "userProfile-new-password" }}
                type={
                  passwordFieldVisibilities.newPassword ? "text" : "password"
                }
                value={password.newPassword}
                onChange={onChangePassword("newPassword")}
                allowClear={false}
              />
              <ToggleVisibilityIcon
                visible={passwordFieldVisibilities.newPassword}
                onToggle={createOnToggleVisibilityHandler("newPassword")}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={baseClasses.textField}>
              <label
                className={classes.textLabel}
                htmlFor="userProfile-reenter_password"
              >
                Re-enter New Password
              </label>
              <LockOutlined className="lock-outlined-icon" />
              <SmarterTextField
                id="userProfile-reenter_password"
                inputProps={{
                  "data-testid": "userProfile-reenter-new-password",
                }}
                type={
                  passwordFieldVisibilities.confirmPassword
                    ? "text"
                    : "password"
                }
                value={password.confirmPassword}
                onChange={onChangePassword("confirmPassword")}
                allowClear={false}
                error={isPasswordError}
                helperText={isPasswordError ? "Passwords don’t match" : ""}
              />
              <ToggleVisibilityIcon
                visible={passwordFieldVisibilities.confirmPassword}
                onToggle={createOnToggleVisibilityHandler("confirmPassword")}
              />
            </div>
          </Grid>

          {!isAuth0 && 
            (<div className={classes.requiredLabels}>
              <span className={classes.passwordRequirementTitle}>
                Password must contain
              </span>
              {passwordConfiguration.map(config => {
                return (
                  <div
                    className={classes.passwordRequirementElement}
                    style={{
                      color: errors[config[0]] ? errorColor : successColor,
                    }}
                    key={config[0]}
                  >
                    <Check className={classes.passwordRequirementImage} /> {config[1]}
                  </div>
                )
              })}
              </div>

            ) }
        </Grid>
        <div className={baseClasses.actionContainer}>
          <Button
            variant="outlined"
            className={classes.buttonCancel}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.buttonContinue}
            disabled={!isAuth0 ? false : isChangeButtonPasswordDisabled}
            onClick={onSubmit}
            data-testid="userProfile-accountSecurity-change-password-button"
          >
            Change Password
          </Button>
        </div>
      </div>

      <UserProfileDialog
        onClose={userDialogClose}
        isOpen={isDialogOpen}
        type={activeModalType}
        onConfirm={dialogConfirmation}
      />
    </PageLayout>
  );
};
