import React, { useState, useEffect } from 'react';
import { isBefore } from 'date-fns';
import { ResetPasswordPage } from './ResetPasswordPage';
import { postForgotPassword } from '../../apis';
import { SmarterTextField } from '@components/input';
import { FormItem } from '../../components/form/FormItem.jsx';
import { validateEmail } from "../../utils/utils.js";

export const EnterEmail = ({ onSubmit }) => {
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState(sessionStorage.getItem('email') || '');
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    let intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onChangeEmail = (value) => {
    setEmail(value);
  };

  const forgotPassword = async () => {
    let isEmailValid = validateEmail(email);
    if(!isEmailValid) {
      setEmailError('');
      sessionStorage.setItem('email', email);
      try {
        const formData = new FormData();
        formData.append("email", email);
        await postForgotPassword(formData);
        let now = new Date();
        now.setSeconds(now.getSeconds() + 90);
        localStorage.setItem('endTime', now);
      } catch (error) {
        console.log(error);
      }
      onSubmit();
    }
    else {
     setEmailError('Invalid email format')
    }
  };
  const isButtonDisabled = isBefore(new Date(), new Date(localStorage.getItem('endTime'))) || email.length === 0;
 
  return (
    <ResetPasswordPage
      title="Reset password"
      description="Please enter the email address you used to register with us. A verification code will be
        sent to your email address to reset your password"
      buttonProps={{
        text: 'Continue',
        onClick: forgotPassword,
        disabled: isButtonDisabled,
      }}
    >
      <FormItem label="Email">
        <SmarterTextField
          onChange={onChangeEmail}
          value={email}
          id="resetPassword-reset-email"
          inputProps={{ 'data-testid': 'resetPassword-reset-email' }}
          error={emailError}
          helperText={emailError}
        />
      </FormItem>
    </ResetPasswordPage>
  );
};
