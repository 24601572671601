import { Alert, MasterLayout } from '@smarterhealth/ui-components';
import { useEffect, useState } from 'react';
import { getMetaData, getRoleUserDoctor, getUserPayors, postPayorToken } from 'apis/apis';
import { PREVIOUS_URL_KEY, SELECTED_PAYOR_KEY, USER_PAYORS } from 'features/common/config';
import { useLocation, useNavigate } from 'react-router-dom';
import { PayorConfirmationContext } from '../context/PayorConfirmationContext';
import { MultiPayor } from '@smarterhealth/utilities';

export const ProtectedLayout = ({ children, isHideSwitchPayor, ...props }) => {
  const { error, setSelectedPayor, isLoading, switchPayorAction } = MultiPayor.useSwitchPayor({
    getMetadata: getMetaData,
    getTokenByPayor: postPayorToken,
    getRoleUserDoctor: getRoleUserDoctor,
    onSuccessSwitchPayor: handleAfterSwitchPayor,
  });

  const newSelection = localStorage.getItem(SELECTED_PAYOR_KEY);

  const [payors, setPayors] = useState([]);
  const [confirmSelectedPayor, setConfirmSelectedPayor] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  function handleAfterSwitchPayor() {
    if (location?.pathname.includes('choose-payor')) {
      const previousUrl = localStorage.getItem(PREVIOUS_URL_KEY) || null;
      if (previousUrl && !previousUrl.includes('login')) {
        return (window.location.href = previousUrl);
      }
    }
    return (window.location.href = '/uapp/choose-menu');
  }

  const cacheUserPayor = localStorage.getItem(USER_PAYORS);

  const newHeaderProps = {
    ...props.headerProps,
    payors: !isHideSwitchPayor && payors,
    selectedPayor: confirmSelectedPayor,
    handleChangePayor: handleChangePayor,
  };

  const newProps = { ...props, headerProps: newHeaderProps };

  function handleChangePayor(value) {
    switchPayorAction(value);
  }

  useEffect(() => {
    async function fetchUserPayor() {
      try {
        const res = await getUserPayors();
        if (res?.data) {
          let temp = [];
          res.data.forEach((item) => {
            temp.push({
              ...item,
              payorName: item.displayName,
              id: item.payorId,
            });

            if (item.selected) {
              setConfirmSelectedPayor(newSelection || item.payorId);
            }
          });
          localStorage.setItem(USER_PAYORS, JSON.stringify(temp));
          setPayors(temp);
        }
      } catch (errors) {
        setPayors([]);
        setConfirmSelectedPayor(null);
      }
    }

    if (cacheUserPayor && JSON.parse(cacheUserPayor)) {
      setPayors(JSON.parse(cacheUserPayor));
    } else if (!isHideSwitchPayor) {
      fetchUserPayor();
    }
  }, [navigate]);

  useEffect(() => {
    if (newSelection && !isLoading) {
      setConfirmSelectedPayor(newSelection);
    }
  }, [newSelection, isLoading]);

  return (
    <MasterLayout {...newProps}>
      {error && <Alert severity="error">{error}</Alert>}
      <PayorConfirmationContext.Provider
        value={{
          setSelectedPayor,
          handleChangePayor,
        }}
      >
        {children}
      </PayorConfirmationContext.Provider>
    </MasterLayout>
  );
};
